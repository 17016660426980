import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useApi } from "../../../context/apiContext";
import { getGallery } from "../../../api/galleryApi";

export function  NavpoliticasComponent({politicas}){
    const { getTextByKey,getPropiertyByLocale } = useApi();
  
    

    return(
         <ul className='NavPoliticas'>
           {politicas?.map((politica, index) => (
            <li className="mb-2">
            {politica?.url ? 
              <a href={politica.url} target="blank" className='gray-1-color mb-1'>
              {getPropiertyByLocale(politica.names, 'titulo')}
            </a> :
            <NavLink to={politica?.link}  className='gray-1-color'>
              {getPropiertyByLocale(politica.names, 'titulo')}
            </NavLink>
       
            }          
            </li>
           ))}
         
             
       </ul>
    );
}